body.body--vcard {
  background: #111;
  color: #eee;

  display: grid;
  place-items: center;
}

.vcard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 8rem;
  margin-bottom: 8rem;
  text-align: center;

  h1 {
    /* max-width: clamp(10rem, 80vw, 60rem); */
  }

  span {
    visibility: hidden;
    position: absolute;
    left: -5000rem;
  }

  p,
  a {
  }

  a {
    color: inherit;
  }
}
