:root {
  --button-padding: 0.75rem 2rem;
  --button-padding-large: 0.75rem 3rem;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}

.button {
  display: inline-block;
  vertical-align: middle;
  font-size: inherit;
  user-select: none;
  line-height: 1;
  text-decoration: none;
  padding: var(--button-padding);
  color: var(--black);
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 1.25rem;
  transition: border-radius var(--general-transition-speed), background-color
    var(--general-transition-speed);
  border-radius: 1.5rem;

  &.button--primary,
  & {
    border: 1px solid var(--black);

    &.--inverted,
    .section--dark & {
      color: var(--white);
      border: 1px solid var(--white);
    }
  }

  &.button--white {
    background-color: var(--white);
  }

  &.button--secondary {
    background-color: var(--black);
    color: var(--white);

    &.--inverted {
      background-color: var(--white);
      color: var(--black);
    }
  }

  &.button--donation {
    background-color: var(--yellow-light);
    color: var(--black);

    &:hover,
    &:focus {
      background-color: var(--yellow-medium);
    }
  }

  &.button--proposal {
    background-color: var(--purple-light);
    color: var(--black);

    &:hover,
    &:focus {
      background-color: var(--purple-medium);
    }
  }

  &.button--orange {
    background-color: var(--light-orange);
    color: var(--black);

    &:hover,
    &:focus {
      background-color: var(--orange-medium);
    }
  }

  &.button--green-medium {
    background-color: var(--green-medium);
    color: var(--black);

    &:hover,
    &:focus {
      background-color: var(--green-medium);
    }
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &:focus-visible {
    outline: 0.15rem dashed var(--gray);
    border-radius: 0;
    outline-offset: 5px;
  }

  &:hover {
    border-radius: 0;
    outline: none;
  }
}

.button--large {
  padding: var(--button-padding);

  @media (--media-md) {
    padding: var(--button-padding-large);
  }
}

.button--margin {
  margin-bottom: var(--general-margin);
}

.button--link {
  background: none;
  padding-left: 0;
  padding-right: 0;
}

.button--delete {
  color: var(--error);
}
