.f3cc {
  --f3cc-background: #fab4ff;
  --f3cc-button-background: #fff;
  --f3cc-accept-background: #ff8fa0;

  .f3cc-title {
    font-weight: normal;
  }

  .f3cc-button {
    border-radius: 9rem;
  }
}
