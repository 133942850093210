.votinglist {
  position: fixed;
  display: flex;
  top: 10rem;
  right: 0;
  height: 4.1rem;
  width: 2.75rem;
  border-top-left-radius: var(--radius-sm);
  border-bottom-left-radius: var(--radius-sm);
  background-color: var(--light-orange);
  box-shadow: 3px 2px 11px -5px var(--black);
  z-index: 80;
  @media (--media-md) {
    height: 16rem;
    width: 3.65rem;
  }

  .votinglist--active & {
    padding: 1rem 0 0 0.25rem;
    width: 90vw;
    height: auto;

    @media (--media-md) {
      width: 31.8rem;
      padding: 1rem 0 0 0.75rem;
    }
  }
}

.votinglist-toggle {
  position: absolute;
  height: 100%;
  width: 100%;

  svg {
    margin-top: 5px;
    transform: rotate(0deg);
    &.icon {
      margin-right: 0;
    }
  }

  @media (--media-md) {
    /* position: relative; */
    display: flex;
    width: 3rem;
    svg {
      margin: 1rem 0 0 0.75rem;
      /* margin: 1rem; */
    }
  }

  .votinglist--active & {
    position: relative;
    display: block;
    width: 3rem;
    svg {
      margin: 5px 0 0 0;
      transform: rotate(180deg);
    }
  }
}

.votinglist-panel {
  padding: 0.5rem 1rem 8rem 1rem;
  visibility: visible;
  max-height: calc(100vh - 10rem);
  overflow-y: scroll;
  scrollbar-width: none;
  visibility: hidden;

  @media (--media-md) {
    padding: 0rem 2rem 8rem 2rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .votinglist--active & {
    visibility: visible;
  }
}

.watchlist-body-wrapper {
  margin-bottom: var(--general-margin);
}

.form {
  .errorlist {
    margin-bottom: var(--general-margin);
    background-color: var(--ui-red);
    color: var(--white);
    border-radius: var(--radius-xs);
    text-align: center;
    padding: 0.2rem;
  }
  &.form--votinglist {
    pointer-events: none;
    @media (--media-md) {
      padding-right: 3rem;
    }

    .widget--checkboxinput label {
      width: fit-content;
      color: var(--black);
    }

    .widget--checkboxinput .field__input {
      border: 1px solid var(--black);
      background-color: var(--white);
    }

    .field {
      margin-bottom: var(--general-block-margin);
    }

    input {
      padding: 0.15rem 1rem;
    }

    .votinglist--active & {
      pointer-events: all;
    }
  }
}

.button {
  .active & {
    background-color: var(--black);
    color: var(--white);
  }
  /* &.deactivate {
    background-color: var(--light-gray);
    color: var(--light-gray);
    border: 1px solid var(--light-gray);
    cursor: not-allowed;
  } */
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-body__main {
  overflow: hidden;
  hyphens: auto;
}

.solution-votes {
  display: flex;
  align-items: center;

  svg path,
  svg use {
    fill: var(--black);
  }

  &.solution-votes--voting,
  &.solution-votes--detail {
    cursor: pointer;
    svg path,
    svg use {
      fill: none;
    }
    .active & {
      svg path,
      svg use {
        fill: var(--black);
      }
    }
  }

  &.deactivate {
    svg path,
    svg use {
      /* fill: var(--light-gray); */
      stroke: var(--light-gray);
    }
    cursor: not-allowed;
  }

  span {
    pointer-events: none;
  }

  svg {
    pointer-events: none;
    margin-left: 0.75rem;
  }
}

.solution-votes--detail {
  margin-left: var(--general-margin);
}

.votinglist-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--general-margin) 0;
  border-top: 1px solid var(--black);

  &:last-child {
    border-bottom: 1px solid var(--black);
  }

  h3 {
    margin: 0 1rem 0 0;
    hyphens: auto;
  }
}

.votinglist-entry--add {
  a {
    width: 100%;
    display: flex;
  }

  h4 {
    margin: 0;
  }

  svg {
    margin: 0 auto;
  }
}

.votinglist-entry__icon {
  display: flex;
  align-items: center;
}

.solution__vote-count {
  background-color: var(--light-orange);
  width: fit-content;
  padding: 0.5rem 0.75rem;
  margin-bottom: var(--general-margin);
  border-top-right-radius: var(--radius-xs);
  border-top-left-radius: var(--radius-xs);

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;

    @media (--media-md) {
      height: 2rem;
      width: 2rem;
    }

    @media (--media-lg) {
      height: auto;
      width: auto;
    }
  }

  .active & {
    svg path,
    svg use {
      fill: var(--black);
    }
  }
}

.solution__voting-container {
  display: flex;
}

.solution__video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 0;
  padding-top: 56.25%;
}

.solution__video-link {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
}

.solution__video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(7.2rem / 2);
  height: calc(8rem / 2);
  background-image: url("icons/play-button.svg");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 5;
  user-select: none;
  pointer-events: none;

  @media (--media-lg) {
    width: 7.2rem;
    height: 8rem;
  }
}

.votinglist-panel__info-box {
  display: flex;
  align-items: center;
  .votinglist-panel__info-text {
    a {
      position: relative;
      padding-left: 1.8rem;
      white-space: nowrap;
      .icon {
        &::before {
          content: " ";
        }
        position: absolute;
        left: 0;
        top: -0.36rem;
        margin: 0;
      }
    }
  }
}
.votinglist-panel__info-icon {
  width: var(--icon-size);
  height: var(--icon-size);
  flex: none;
  margin: 5px 1rem 0 0;
}
