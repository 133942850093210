a {
  color: var(--black);
  position: relative;
  overflow-wrap: break-word;

  &:hover {
    color: var(--gray);
  }

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline: 0.15rem dashed var(--gray);
    color: var(--gray);
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &.no-decoration {
    text-decoration: none;
  }

  &.no-hover {
    &:hover,
    &:focus {
      color: inherit;
    }
  }
}

h1,
.h1 {
  font-size: responsive 40px 78px;
  line-height: 1.08em;
  lower-font-range: 540px;
  upper-font-range: 1440px;
}

h2,
.h2 {
  font-size: responsive 32px 64px;
  line-height: 1.15em;
  lower-font-range: 540px;
  upper-font-range: 1440px;
}

h3,
.h3 {
  font-size: responsive 21px 32px;
  line-height: responsive 1.3em 1.2em;
  line-height-range: 33.75em 64em;
  lower-font-range: 540px;
  upper-font-range: 1440px;
}

h4 {
  font-size: responsive 16px 24px;
  line-height: responsive 1.5em 1.2em;
  line-height-range: 33.75em 64em;
  lower-font-range: 540px;
  upper-font-range: 1440px;
  margin-bottom: 20px;
  @media (--media-md) {
    margin-bottom: 30px;
  }
}

h1,
.h1,
h2,
.h2 {
  letter-spacing: -0.8px;
}

.text--display,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
h5 {
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
}

.text--margin-half {
  display: block;
  margin-bottom: 0.75rem;
}

.text--margin,
.text--margin > p,
h1,
h2,
h3 {
  display: block;
  margin-bottom: var(--general-margin);

  p {
    margin-bottom: var(--general-block-margin);
  }
}

.text--margin-x2 {
  display: block;
  margin-bottom: var(--general-margin-x2);
}

h3,
.h3,
h4 {
  line-height: responsive 1.3em 1.25em;
  line-height-range: 33.75em 64em;
  letter-spacing: -0.2px;
}

.text--medium {
  font-size: responsive 16px 20px;
  line-height: responsive 1.5em 1.4em;
  line-height-range: 33.75em 64em;
  lower-font-range: 540px;
  upper-font-range: 1440px;
}

.text--large {
  font-size: responsive 18px 24px;
  line-height: responsive 1.5em 1.4em;
  line-height-range: 33.75em 64em;
  lower-font-range: 540px;
  upper-font-range: 1440px;
}

.text--display {
  font-size: responsive 40px 108px;
  line-height: 1.15em;
  letter-spacing: -1.34px;
  lower-font-range: 540px;
  upper-font-range: 1440px;
}

.text--oversized {
  font-size: responsive 84px 108px;
  line-height: 1.15em;
  letter-spacing: -1.34px;
  lower-font-range: 540px;
  upper-font-range: 1440px;
}

.text--white {
  color: var(--white);
}

.text--black {
  color: var(--black);
}

address {
  font-style: normal;
  line-height: var(--line-height-footer);
}

.text--block {
  display: block;
}

.text--screenreader {
  font-size: 0;
}

.text--no-wrap {
  white-space: nowrap;
}
