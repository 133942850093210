/* Accordion */

.accordion {
  display: flex;
  flex-direction: column;
  @media (--media-lg) {
    flex-direction: row;
  }
}

.accordion-item {
  position: relative;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  border-bottom-left-radius: var(--radius-sm);
  border-bottom-right-radius: var(--radius-sm);
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    top: -1.5rem;
    bottom: 0;
    left: 0;
    height: 1.5rem;
    width: 100%;
  }

  &:first-of-type {
    &:before {
      top: 0;
      height: 0;
    }
  }

  @media (--media-lg) {
    flex-direction: row;
    height: 31rem;
    flex: 1 1 auto;
    border-top-right-radius: var(--radius-md);
    border-bottom-right-radius: var(--radius-md);
    border-bottom-left-radius: 0;

    &:before {
      content: "";
      position: absolute;
      left: -2rem;
      top: 0;
      bottom: auto;
      right: auto;
      height: 100%;
      width: 2rem;
    }

    &:first-of-type {
      &:before {
        left: 0;
        width: 0;
      }
    }
  }
}

.accordion-header {
  position: relative;
  display: flex;
  justify-content: space-between;

  @media (--media-lg) {
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
  }
}

.accordion-title {
  @media (--media-lg) {
    position: absolute;
    left: 0;
    bottom: -3rem;
    transform-origin: 0 0;
    transform: rotate(-90deg);
  }
}

.accordion-body {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  transition: height 1s cubic-bezier(0.19, 1, 0.22, 1);

  .accordion-item--open & {
    height: 15rem;
  }

  @media (--media-lg) {
    margin-left: 3rem;
    height: 100%;
    width: 0;
    transition: width 1s cubic-bezier(0.19, 1, 0.22, 1);

    .accordion-item--open & {
      width: 30rem;
    }
  }
}

.accordion-item__content {
  position: absolute;
  opacity: 0;
  transition: opacity 0s;

  @media (--media-lg) {
    min-width: 30rem;
  }

  .accordion-item--open & {
    opacity: 1;
    transition: opacity 0.35s 0.2s;
  }
}
