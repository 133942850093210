.box {
  position: relative;
  padding: 3rem 2rem 1.5rem;
  display: grid;
  justify-content: space-between;
  place-items: flex-end flex-start;
  transition: border-radius var(--general-transition-speed);

  &:hover,
  &:focus {
    color: var(--black);
    border-top-right-radius: var(--radius-lg);
    border-bottom-right-radius: var(--radius-lg);
  }

  @media (--media-lg) {
    height: 100%;
    min-height: 30em;
  }
}

.box-link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.box--large {
  padding-top: 6rem;
}

.box--blue {
  background-color: var(--blue-light);
}

.box--green {
  background-color: var(--green-light);
}

.box--red {
  background-color: var(--red-light);
}

.box__header {
  margin-bottom: 3rem;

  @media (--media-md) {
    margin-bottom: 8rem;
  }
}

.box__content {
  margin-bottom: auto;
}

.box-logo__container {
  display: flex;
  justify-content: center;
  height: 8rem;

  img {
    display: block;
    height: 100%;
    object-fit: contain;
  }
}

.teaser {
  position: relative;
  display: grid;
  margin-bottom: var(--general-margin-x2);
}

.teaser--vision {
  margin-bottom: 100px;

  @media (--media-md) {
    margin-bottom: 0;
  }
}

.teaser__content {
  position: relative;
  z-index: 1;
}

.teaser__content--space-between {
  display: grid;
  align-content: space-between;
  height: 100%;
}

.teaser__image {
  position: relative;
  height: 20rem;
  width: 100%;
  margin-left: auto;
  margin-bottom: var(--container-margin);
  z-index: 0;

  @media (--media-md) {
    position: relative;
    height: 30rem;
    width: 30rem;
    margin-bottom: 0;
  }
}

.teaser__image--vision-teaser {
  right: 0;
  transform: translate(4rem, -4rem);

  @media (--media-md) {
    transform: translateY(-5rem);
  }
}

.teaser__image--clima-pitch {
  transform: translate(6rem, -4rem);

  @media (--media-md) {
    transform: none;
  }
}

.teaser__image--left {
  left: 0;
}

.teaser-body {
  p {
    margin-bottom: var(--general-margin);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* cards */

.grid--cards-teaser {
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (--media-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.grid--cards {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-flow: dense;

  @media (--media-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--media-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.cell--full {
  grid-column: span 1;
  height: 100%;

  @media (--media-md) {
    grid-column: span 4;
  }

  @media (--media-lg) {
    grid-area: 1 / 3 / 3 / 3;
  }
}

.cell--large {
  grid-column: span 1;
  grid-row: span 1;
  height: 100%;

  @media (--media-md) {
    grid-column: span 2;
    grid-row: span 2;
  }

  @media (--media-lg) {
    grid-column: span 2;
    grid-row: span 2;
  }
}

.cell--small {
  grid-column: span 1;
  grid-row: span 1;
}

.cell--card {
  grid-row: span 10;
}

.cell--card-full {
  grid-row: span 10;

  @media (--media-md) {
    grid-row: span 20;
  }
}

.card {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 1rem;
  height: 100%;

  &:hover,
  &:focus {
    color: var(--black);
  }

  &:hover img,
  &:focus img {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  img,
  a img {
    display: block;
    width: 100%;
    object-fit: contain;
    background-color: var(--black);

    border-top-right-radius: var(--radius-xs);
    border-top-left-radius: var(--radius-xs);
    transition: border-radius var(--general-transition-speed);
  }
}

.card-body__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .levers,
  .lever {
    margin-bottom: 0;
  }
}

.card-body--solution {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-image__container {
  display: block;

  img {
    height: 266px;
  }
}

.card__video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  height: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
  }
}

.card--shifts {
  background-color: var(--red-light);
}

.card--emissions {
  background-color: var(--blue-light);
}

.card--absorption {
  background-color: var(--green-light);
}

.card--box {
  padding: 1.5rem 1rem;
  grid-template-rows: auto 0fr;
  border-top-right-radius: var(--radius-xs);
  border-top-left-radius: var(--radius-xs);
  transition: border-radius var(--general-transition-speed);

  &:hover,
  &:focus {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.card--full {
  height: 100%;
}
