/* Content Base */

.page--content {
  background-color: var(--black);
}

.content {
  background-color: var(--white);
  border-top-right-radius: var(--radius-xs);
  border-top-left-radius: var(--radius-xs);
  padding: 2rem 0;

  .page--home & {
    padding: 0;
  }

  @media (--media-md) {
    margin-top: var(--general-margin);
    border-top-right-radius: var(--radius-sm);
    border-top-left-radius: var(--radius-sm);
    padding: 4rem 0;

    .page--home & {
      padding: 0;
    }
  }

  @media (--media-lg) {
    margin-top: var(--general-margin);
    border-top-right-radius: var(--radius-lg);
    border-top-left-radius: var(--radius-lg);
  }
}

.image {
  width: 100%;
}

.image--margin {
  margin-bottom: 0.5rem;
}

.image_caption {
  padding-top: 0.5em;
  font-size: 18px;
}

.image--control {
  width: auto;
  max-height: 33rem;
  margin-left: auto;
  margin-right: auto;
}

/* Breadcrumbs */

.content-breadcrumbs {
  position: relative;
  display: flex;
}

.content-breadcrumb {
  display: flex;
  align-self: auto;
  align-items: center;
}

/* Lead */

.content-title {
  margin-top: var(--general-margin);
  margin-bottom: var(--general-margin);

  @media (--media-md) {
    margin-bottom: var(--general-margin-x2);
  }
}

/* Profile snippet */

.content-profile {
  background-color: var(--yellow-medium);
  padding: 1.5rem;

  &.content-profile--yellow {
    background-color: var(--yellow-medium);
  }
  &.content-profile--orange {
    background-color: var(--orange-medium);
  }
}

/* Lever */

.levers {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  &.levers--padding {
    padding: var(--general-margin) 0;
    margin-bottom: 0;
  }
}

.lever {
  display: block;
  border-radius: 16px;
  width: fit-content;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.3rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  transition: border-radius var(--general-transition-speed);

  .levers--padding & {
    margin-bottom: 0;
  }

  &:hover {
    border-radius: 0;
  }
}

.lever--shifts {
  background-color: var(--red-light);
}

.lever--emissions {
  background-color: var(--blue-light);
}

.lever--absorption {
  background-color: var(--green-light);
}

.lever--investment {
  background-color: var(--black);
  color: var(--white);
}

.lever--projects {
  border: 1px solid var(--black);
}

.lever--secondary {
  border: 1px solid var(--black);
  margin-bottom: 0.5rem;
}

.lever--white {
  border: 1px solid var(--white);
  margin-bottom: 0.5rem;
}

/* Quotation */

.quotation-title {
  margin-top: var(--general-margin);
  margin-bottom: 1rem;

  @media (--media-md) {
    margin-bottom: var(--general-margin);
  }
}

/* Typography */

/*
.page--project {
  p,
  li {
    font-size: responsive 16px 20px;
    line-height: responsive 1.5em 1.4em;
    line-height-range: 33.75em 64em;
    lower-font-range: 540px;
    upper-font-range: 1440px;
  }
}

*/

.page--content {
  counter-reset: ol;
}

.richtext {
  p,
  li {
    /* min-size, max-size */
    font-size: responsive 16px 20px;
    line-height: responsive 1.5em 1.42em;
    lower-font-range: 1400px;
    upper-font-range: 1980px;
    /* viewport widths between large screens */
    line-height-range: 33.75em 64em;
    margin-bottom: var(--general-block-margin);
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: var(--general-margin-x2);
    margin-bottom: var(--general-block-margin);

    &:first-child {
      margin-top: 0;
    }
  }

  ol {
    list-style: none;
    padding-left: 0;

    li {
      position: relative;
      align-items: center;
      padding-left: 3rem;

      @media (--media-md) {
        padding-left: 4rem;
      }

      &:before {
        counter-increment: ol 1;
        content: counter(ol);
        position: absolute;
        top: 0.5rem;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        border: 1px solid var(--black);
      }
    }
  }

  ul {
    margin-left: 0;

    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

/* Graphs */

.tipping-points {
  list-style: none;
  counter-reset: point;
  padding-left: 0;

  @media (--media-md) {
    column-count: 2;
  }

  li {
    position: relative;
    padding-left: 3rem;
    margin-bottom: 1.5rem;

    @media (--media-md) {
      padding-left: 3.5rem;
    }

    &:before {
      counter-increment: point 1;
      content: counter(point, upper-alpha);
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      background-color: var(--tipping-points);
      color: var(--black);
    }
  }
}

/* Media element */

@media (--media-sm-only) {
  /* Reverse order of media elements on mobile */
  .media-element > *:last-child {
    order: -1;
  }
}
