:root {
  --icon-size: 40px;
  --icon-small-size: 32px;
}

.social-container {
  display: flex;
}

.social {
  background-repeat: no-repeat;
  transition: all 0.2s ease;

  &.icon--facebook {
    background-image: url("icons/facebook.svg");
  }

  &.icon--twitter {
    background-image: url("icons/twitter.svg");
  }

  &.icon--instagram {
    background-image: url("icons/instagram.svg");
  }

  &.icon--linkedin {
    background-image: url("icons/linkedin.svg");
  }

  &.icon--youtube {
    background-image: url("icons/youtube.svg");
  }

  &.icon--tiktok {
    background-image: url("icons/tiktok.svg");
  }

  &:hover {
    opacity: 0.7;
    transition: all 0.2s ease;
  }
}

.icon--orange {
  svg path {
    fill: var(--orange-medium);
  }
}

.control {
  background-repeat: no-repeat;

  &.icon--arrow-down-white {
    background-image: url("icons/arrow-down-white.svg");
  }

  &.icon--arrow-right-black {
    background-image: url("icons/arrow-right-black.svg");
  }

  &.icon--arrow-right-white {
    background-image: url("icons/arrow-right-white.svg");
  }

  &.icon--arrow-left-black {
    background-image: url("icons/arrow-left-black.svg");
  }

  &.icon--trash {
    background-image: url("icons/trash.svg");
    background-size: contain;
  }
}

.icon {
  display: inline-block;
  font-size: 0;
  width: var(--icon-size);
  height: var(--icon-size);
  margin: 0 auto;
  background-size: cover;
  background: no-repeat 50% 50%;
  margin-right: 1rem;

  &.icon--small {
    width: var(--icon-small-size);
    height: var(--icon-small-size);
  }

  &.icon--last {
    margin-right: 0;
  }

  &.icon--right {
    margin-right: 0;
    margin-left: 1rem;
  }
}
