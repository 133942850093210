:root {
  --container-width: 90rem; /* 1440px */
  --grid-columns: 12;
  --grid-gutter-sm: 1.85rem;
  --grid-gutter-md: 2.5rem;
  --container-gap-sm: 1.5rem;
  --container-gap-md: 3rem;
}

.container {
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-gap-sm);
  padding-right: var(--container-gap-sm);

  &.container--full {
    padding-left: 0;
    padding-right: 0;
  }

  @media (--media-md) {
    padding-left: var(--container-gap-md);
    padding-right: var(--container-gap-md);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
}

.grid--vh100 {
  height: 100%;
  @media (--media-md) {
    min-height: 100vh;
  }
}

.grid--gx {
  column-gap: var(--grid-gutter-sm);

  @media (--media-md) {
    column-gap: var(--grid-gutter-md);
  }
}

.grid--gy {
  row-gap: var(--grid-gutter-sm);

  @media (--media-md) {
    row-gap: var(--grid-gutter-md);
  }
}

.cell {
  /* box-shadow: 0 0 3px 0 blue; */
  --width-sm: var(--grid-columns); /* Default width */
  --width: var(--width-sm);
  grid-column-end: span var(--width);
}

@media (--media-md) {
  .cell {
    --width-md: var(--width-sm);
    --width: var(--width-md);
  }
}

@media (--media-lg) {
  .cell {
    --width-lg: var(--width-md);
    --width: var(--width-lg);
  }
}

@for $i from 1 to 12 {
  .cell.sm-$i {
    --width-sm: $i;
  }
  .cell.md-$i {
    --width-md: $i;
  }
  .cell.lg-$i {
    --width-lg: $i;
  }
}
