:root {
  /* Grays */
  --myst: #f0f0f0;
  --gray: #6d6c70;
  --light-gray: #b6b6b7;
  --dark-gray: #333;
  --text: #5c5e51;
  --darkblack: #111;
  --black: #0a0a0f;
  --white: #fff;

  /* Colors */
  --yellow-light: #f5f096;
  --yellow-medium: #f5f04b;
  --yellow-dark: #b4af37;

  --blue-light: #8cd7f5;
  --blue-medium: #55c8f0;
  --blue-dark: #4191b4;

  --green-light: #c8f5a5;
  --green-medium: #aff573;
  --green-dark: #6e9b4b;

  --red-light: #ff8fa0;
  --red-medium: #ff5a78;
  --red-dark: #be465a;

  --purple-light: #fab4ff;
  --purple-medium: #f582ff;
  --purple-dark: #b961be;

  --light-orange: #f5c387;
  --orange-medium: #f5a03c;

  --turquoise-light: #a0f0dc;
  --tipping-points: #f0b8fb;

  --green: #d9de50;
  --cyan: #6ff199;
  --fire: #fd6206;
  --sludge: #a3a925;
  --blue: #7777ff;

  --ui-green: #306f13;
  --ui-red: #d31230;

  --shadow: 0 0 4px 0 rgba(50, 50, 150, 0.3);
  --hairline: 1px solid rgba(0, 0, 0, 0.2);

  --general-block-margin: 1.5rem;
  --general-margin: 2rem;
  --general-margin-x2: 3.5rem;
  --line-height-footer: 1.5;
  --general-transition-speed: 0.3s;

  --radius-xl: 96px;
  --radius-lg: 48px;
  --radius-md: 32px;
  --radius-sm: 24px;
  --radius-xs: 16px;
}

@font-face {
  font-family: "Atak";
  src: url("fonts/Atak-Regular.woff") format("woff"),
    url("fonts/Atak-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

html {
  /* min-size, max-size */
  font-size: responsive 16px 18px;
  line-height: responsive 1.5em 1.4em;
  lower-font-range: 1400px;
  upper-font-range: 1980px;
  /* viewport widths between large screens */
  line-height-range: 33.75em 64em;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Atak", sans-serif;
  letter-spacing: -0.02em;
  font-weight: 400;
  color: var(--black);
  background: var(--white);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(107, 107, 107, 0);
    transition: background-color 0.6s cubic-bezier(0.41, 0.29, 0.18, 0.97);
    pointer-events: none;
    z-index: 50;
    mix-blend-mode: multiply;
  }

  &.menu-is-open {
    &:before {
      background-color: rgba(107, 107, 107, 0.7);
    }
  }

  &.votinglist--active {
    overflow: hidden;
    @media (--media-md) {
      overflow: auto;
    }
  }
}

.main {
  position: relative;
  height: 100%;
  flex: 1 0 auto;
  overflow: hidden;

  padding-top: 100px;

  @media (--media-md) {
    padding-top: 120px;
  }

  .menu-is-open & {
    user-focus: none;
    pointer-events: none;
  }
}

.space--sm {
  margin-bottom: var(--general-margin);
}

.space--md {
  margin-bottom: 50px;

  @media (--media-md) {
    margin-bottom: 80px;
  }
}

.space--lg {
  margin-bottom: 100px;
  @media (--media-md) {
    margin-bottom: 150px;
  }
}

.space--xl {
  margin-bottom: 150px;
  @media (--media-md) {
    margin-bottom: 200px;
  }
}

.section--padding {
  padding-top: 200px;
}

.section--border {
  position: relative;
}

.section--gab {
  margin-top: auto;
}

.section--border-bottom {
  border-bottom-right-radius: var(--radius-md);
  border-bottom-left-radius: var(--radius-md);
  padding-bottom: 8rem;

  @media (--media-md) {
    border-bottom-right-radius: var(--radius-lg);
    border-bottom-left-radius: var(--radius-lg);
  }

  @media (--media-lg) {
    border-bottom-right-radius: var(--radius-xl);
    border-bottom-left-radius: var(--radius-xl);
  }
}

.section--border-top {
  padding-top: 10rem;

  @media (--media-md) {
    padding-top: 15rem;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 5rem;
    width: 100%;
    background-color: var(--white);
    border-bottom-right-radius: var(--radius-md);
    border-bottom-left-radius: var(--radius-md);

    @media (--media-md) {
      border-bottom-right-radius: var(--radius-lg);
      border-bottom-left-radius: var(--radius-lg);
    }
    @media (--media-lg) {
      border-bottom-right-radius: var(--radius-xl);
      border-bottom-left-radius: var(--radius-xl);
    }
  }
}

.section--black {
  background-color: var(--black);
  color: var(--white);
}

.section--dark {
  color: var(--white);
}

.relative {
  position: relative;
}

.responsive-image {
  margin: 0;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.responsive-image__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain";
}

.container--margin {
  margin-bottom: var(--general-margin-x2);
}

.chart-caption {
  padding-top: 0.35rem;
  border-top: 1px solid var(--black);
}

.cn-badge {
  position: absolute;
  height: 10rem;
  width: 10rem;
  right: 0;
  top: 0;

  @media (--media-md) {
    height: 15rem;
    width: 15rem;
  }

  &.cn-badge--newsletter {
    top: -8rem;
    right: var(--container-gap-sm);
    @media (--media-md) {
      top: -20rem;
      right: var(--container-gap-md);
    }
  }
}

.cn-badge-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  transition: border-radius var(--general-transition-speed);
  transform: rotate(-12deg);

  &.cn-badge-body--yellow {
    background-color: var(--yellow-light);
  }

  &.cn-badge-body--turquoise {
    background-color: var(--turquoise-light);
  }

  &:hover,
  &:focus {
    border-radius: 0;
  }
}

.cn-badge-link {
  display: block;
  padding: 1rem;
  text-align: center;
}

.flex-order-1 {
  order: 1;
  @media (--media-md) {
    order: 2;
  }
}
.flex-order-2 {
  order: 2;

  @media (--media-md) {
    order: 1;
  }
}

.responsive-embed {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
