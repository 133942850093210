.footer {
  background-color: var(--black);
  padding: 4rem 0 2rem 0;

  @media (--media-md) {
    padding: 8rem 0 5rem 0;
  }
}

.footer__images {
  display: flex;
  margin-top: 3rem;
}

.footer__image {
  &:first-child {
    margin-right: var(--general-margin);
  }
}

.footer-nav {
  ul {
    line-height: var(--line-height-footer);
  }
}

.footer__item {
  margin-bottom: 3rem;
}
