.icon--list-control {
  --icon-size: 1rem;
  display: block;
  margin-right: -0.33rem;
  margin-left: 0.5rem;
}

.icon--list-control.arrow--down {
  background-image: url(icons/control-arrow-down-black.svg);
}

.icon--list-control.arrow--up {
  background-image: url(icons/control-arrow-up-black.svg);
}
.icon--list-control.reset {
  background-image: url(icons/control-reset-black.svg);
}
.icon--list-control.search {
  position: absolute;
  top: calc(50% - 0.5rem);
  right: 1rem;
  background-image: url(icons/search.svg);
}

.solution-list--controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -5px 2rem -5px;

  input,
  button,
  input[type="radio"] + label {
    border: 1px solid var(--black);
    padding: 0.33rem 1rem;

    line-height: 1.33rem;
    border-radius: 2rem;
    margin: 5px;

    display: flex;
    align-items: center;

    transition: border-radius var(--general-transition-speed), background-color
      var(--general-transition-speed);

    &:hover {
      border-radius: 0;
    }
  }

  .radio-wrap,
  .search-wrap,
  .button-wrap {
    position: relative;
  }

  .radio-wrap {
    label {
      display: block;
      cursor: pointer;
      white-space: nowrap;
      span {
        display: block;
      }
    }
  }

  input[type="text"] {
    padding-right: 2rem;
  }

  input[type="radio"]:checked + label {
    color: var(--white);
    background: var(--black);
    .icon--list-control.arrow--down {
      background-image: url(icons/control-arrow-down-white.svg);
    }
    .icon--list-control.arrow--up {
      background-image: url(icons/control-arrow-up-white.svg);
    }
  }

  input[type="text"]:focus-visible,
  input[type="radio"]:focus-visible + label,
  button:focus-visible {
    outline: 0.15rem dashed var(--gray);
    outline-offset: 5px;
    border-radius: 0;
  }
}
