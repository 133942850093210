.table {
  border-collapse: collapse;

  td,
  th {
    text-align: left;
    vertical-align: top;
    padding: 0.25rem 0.5rem 0.25rem;
  }
}

.table--striped {
  tbody > tr:nth-child(2n + 1) {
    td,
    th {
      background: var(--myst);
    }
  }
}
