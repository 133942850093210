.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin: 1rem 0;
}

.pagination__pages {
  display: flex;
  gap: 0.25em;
}

.pagination__pages > * {
  padding: 0.25em 0.75em;
  text-decoration: none;
  transition: background-color 0.2s;
}

.pagination__pages > *:not(.disabled):not(.active):hover {
  opacity: 1;
}

.pagination__pages > .disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.pagination__pages > .active {
}
